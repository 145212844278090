import {Configuration, SkusApi} from '@/api';
import CustomToast from '@/components/CustomToast';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import ProductForm from '@/modules/settings/components/ProductForm';
import {SkuCreate, SkuUpdate} from '@/modules/settings/models/Sku';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {SettingsProductListPath} from '@/modules/settings/paths';
import {CubeIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

const ProductAdd = () => {
    const {currentUser} = useAuth();

    const navigate = useNavigate();

    const {
        supplierId: userSupplierId,
    } = currentUser() || {};

    const skusApi = new SkusApi(new Configuration(useAuth));

    const handleSubmit = async (sku: SkuCreate | SkuUpdate) => {
        try {
            const newSku = await skusApi.createSku(sku);
            if (!newSku) {
                toast.custom((t) => (
                    <CustomToast
                        id={t.id}
                        message={'Failed to create product. Please try again.'}
                        visible={t.visible}
                        variant="error"
                    />
                ));
                return;
            }

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={`Product "${newSku.name}" created successfully!`}
                    visible={t.visible}
                />
            ));

            navigate(SettingsProductListPath);
        } catch (error) {
            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Failed to create product. Please try again.'}
                    visible={t.visible}
                    variant="error"
                />
            ));
            console.error('Error while creating product:', error);
        }
    };

    return (
        <>
            <SettingsHeader
                title="Add Product"
                icon={<CubeIcon className="w-6 h-6"/>}
            />

            <ProductForm
                onSubmit={handleSubmit}
                supplierId={userSupplierId}
            />
        </>
    );
};

export default ProductAdd;
