import {Configuration, SkusApi} from '@/api';
import useFindSkusQuery from '@/api/queries/useFindSkusQuery';
import Button from '@/components/Button';
import Confirm from '@/components/Confirm';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import {SearchProps} from '@/hooks/useSearch';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {SettingsProductAddPath, SettingsProductEditPath} from '@/modules/settings/paths';
import {Link} from 'react-router-dom';

interface ProductTableProps extends SearchProps {
    supplierId?: string | null;
}

const ProductTable = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    supplierId,
}: ProductTableProps) => {
    const {
        skus,
        isLoading,
        error,
        itemsPerPage,
        invalidateQuery,
    } = useFindSkusQuery(
        page,
        searchQuery,
        {
            supplierId: supplierId ?? undefined,
        },
        [
            {sortBy: 'categoryName', sortDirection: 'asc'},
            {sortBy: 'skuName', sortDirection: 'asc'},
        ],
    );

    const skusApi = new SkusApi(new Configuration(useAuth));

    const handleDeleteProduct = async (id: string) => {
        try {
            await skusApi.deleteSku(id);

            await invalidateQuery();
        } catch (error) {
            console.error('Error while deleting product:', error);
        }
    };

    if (!isLoading && !error && skus.items.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center gap-8">
                <p>No products found.</p>

                <Button
                    to={SettingsProductAddPath}
                    variant="primary"
                >
                    Click here to add a new product
                </Button>
            </div>
        );
    }

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {skus.items.map((sku) => {
                        if (sku.id === undefined) {
                            return;
                        }
                        return (
                            <tr key={sku.id}>
                                <td className="w-1/2">
                                    <Link
                                        to={SettingsProductEditPath.withId(sku.id)}
                                        className="text-blue-500 hover:underline"
                                    >
                                        {sku.name}
                                    </Link>
                                </td>
                                <td>{sku.product?.productCategory && sku.product?.productCategory.name}</td>
                                <td>
                                    <Confirm
                                        onConfirm={() => handleDeleteProduct(sku.id)}
                                        message={`Do you want to delete product: ${sku.name}?`}
                                    >
                                        {(handleClick) => (
                                            <Button
                                                onClick={handleClick}
                                                variant="delete"
                                                size="xs"
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </Confirm>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={itemsPerPage}
                    totalCount={skus.totalCount || 0}
                    estimatedTotalCount={skus.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default ProductTable;
