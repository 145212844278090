import {useState} from 'react';
import Button from '@/components/Button';
import PriceInput from '@/components/PriceInput';
import ProductCategorySelectBox from '@/components/ProductCategorySelectBox';
import ToggleSwitch from '@/components/ToggleSwitch';
import {useSettings} from '@/contexts/SettingsContext';
import ProductCategory from '@/modules/settings/models/ProductCategory';
import Sku, {SkuCreate, SkuUpdate} from '@/modules/settings/models/Sku';
import {getCurrencySymbol} from '@/utils/currency';
import {withMinimumDelay} from '@/utils/delay';
import {withLoading} from '@/utils/loading';
import {CheckIcon} from '@heroicons/react/24/solid';
import {useForm} from 'react-hook-form';

interface ProductFormProps {
    sku?: Sku;
    onSubmit: (product: SkuCreate | SkuUpdate) => Promise<void>;
    supplierId?: string;
}

const ProductForm = ({sku, onSubmit, supplierId}: ProductFormProps) => {
    const {currencyCode} = useSettings();
    const [loading, setLoading] = useState(false);

    const currencySymbol = getCurrencySymbol(currencyCode);

    const currentPrice = sku?.prices?.find(price => price.price.currencyCode === currencyCode);
    const price = {price: currentPrice?.price || {amount: 0, currencyCode}};

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors},
    } = useForm<SkuCreate | SkuUpdate>({
        defaultValues: sku ? {...sku, price} : {
            status: 'ACTIVE',
            supplierId,
            price,
        },
    });

    const status = watch('status');
    const productCategory = watch('product.productCategory');
    const priceAmount = watch('price.price.amount');

    const handleStatusChange = (value: boolean) => {
        setValue('status', value? 'ACTIVE' : 'INACTIVE');
    };

    const handleProductCategoryChange = (value: ProductCategory | null) => {
        setValue('product.productCategoryId', value ? value.id : undefined);
        setValue('product.productCategory', value ? value : undefined);
    };

    const handlePriceChange = (amount: number) => {
        setValue('price.price.amount', amount);
        setValue('price.price.currencyCode', currencyCode);
    };

    const onSubmitForm = withLoading(withMinimumDelay(onSubmit, 500), setLoading);

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="grid grid-cols-2 gap-4 lg:gap-6">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        {...register('name', {required: 'Name is required'})}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.name && <span className="text-red-500">{errors.name.message}</span>}
                </div>

                <div className="col-span-2">
                    <label htmlFor="description" className="block font-medium text-gray-700">
                        Description:
                    </label>
                    <textarea
                        id="description"
                        {...register('description')}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>

                <div className="col-span-2">
                    <label htmlFor="externalCode" className="block font-medium text-gray-700">
                        Code:
                    </label>
                    <input
                        type="text"
                        id="externalCode"
                        {...register('externalCode', {required: 'Code is required'})}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.externalCode && <span className="text-red-500">{errors.externalCode.message}</span>}
                </div>

                <div className="col-span-1">
                    <ProductCategorySelectBox
                        value={productCategory || sku?.product?.productCategoryId || null}
                        onSelect={handleProductCategoryChange}
                    />
                    <p className="mt-1 text-sm text-gray-500">
                        Select an existing category or add a new one if needed.
                    </p>
                </div>

                <div className="col-span-1">
                    <label htmlFor="productCategoryName" className="block font-medium text-gray-700">
                        New Category:
                    </label>
                    <input
                        type="text"
                        id="productCategoryName"
                        {...register('productCategoryName')}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    <p className="mt-1 text-sm text-gray-500">
                        Enter a name for a new category. This will be added to the list.
                    </p>
                </div>

                <div className="col-span-2">
                    <div className="grid grid-cols-3 gap-4 lg:gap-6">
                        <div className="col-span-1">
                            <label htmlFor="quantity" className="block font-medium text-gray-700">
                                Units per Case:
                            </label>
                            <input
                                type="number"
                                id="quantity"
                                {...register('quantity')}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                            <p className="text-sm text-gray-500 mt-1">
                                Enter the number of items per case (e.g., 8 items per case).
                            </p>
                        </div>

                        <div className="col-span-1">
                            <label htmlFor="moq" className="block font-medium text-gray-700">
                                Minimum Order Quantity (MOQ):
                            </label>
                            <input
                                type="number"
                                id="moq"
                                {...register('moq')}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                            <p className="text-sm text-gray-500 mt-1">
                                Enter the minimum order quantity.
                            </p>
                        </div>

                        <div className="col-span-1">
                            <label htmlFor="weight" className="block font-medium text-gray-700">
                                Weight (kg):
                            </label>
                            <input
                                type="text"
                                id="weight"
                                {...register('weight.value')}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                            <p className="text-sm text-gray-500 mt-1">
                                Enter the weight in kilograms.
                            </p>
                            {errors.weight?.value &&
                                <span className="text-red-500">{errors.weight.value.message}</span>}
                        </div>
                    </div>
                </div>

                <div className="col-span-1">
                    <label htmlFor="price" className="block font-medium text-gray-700 mb-1">
                        Price ({currencySymbol}):
                    </label>
                    <PriceInput
                        value={priceAmount}
                        currencyCode={currencyCode}
                        onChange={handlePriceChange}
                    />
                    {errors.price?.price?.amount &&
                        <span className="text-red-500">{errors.price.price.amount.message}</span>}
                </div>

                <div className="col-span-1">
                    <div className="flex items-end">
                        <ToggleSwitch
                            checked={status === 'ACTIVE'}
                            onChange={handleStatusChange}
                            label={<label className="block font-medium text-gray-700 mb-2">Status:</label>}
                        />
                        <div className="ml-1 mb-1">
                            {status === 'ACTIVE' ? 'Available' : 'Unavailable'}
                        </div>
                    </div>
                </div>

                <div className="col-span-2">
                    <fieldset className="border border-gray-300 rounded-md p-4">
                        <legend className="text-lg font-medium text-gray-700">Dimensions</legend>
                        <div className="grid grid-cols-3 gap-4 mt-4">
                            <div>
                                <label htmlFor="length" className="block text-sm font-medium text-gray-700">
                                    Length (cm):
                                </label>
                                <input
                                    type="number"
                                    id="length"
                                    {...register('dimensions.length')}
                                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                                />
                                {errors.dimensions?.length &&
                                    <span className="text-red-500">{errors.dimensions.length.message}</span>}
                            </div>

                            <div>
                                <label htmlFor="width" className="block text-sm font-medium text-gray-700">
                                    Width (cm):
                                </label>
                                <input
                                    type="number"
                                    id="width"
                                    {...register('dimensions.width')}
                                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                                />
                                {errors.dimensions?.width &&
                                    <span className="text-red-500">{errors.dimensions.width.message}</span>}
                            </div>

                            <div>
                                <label htmlFor="height" className="block text-sm font-medium text-gray-700">
                                    Height (cm):
                                </label>
                                <input
                                    type="number"
                                    id="height"
                                    {...register('dimensions.height')}
                                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                                />
                                {errors.dimensions?.height &&
                                    <span className="text-red-500">{errors.dimensions.height.message}</span>}
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>

            <div className="mt-4 flex justify-end">
                <Button
                    type="submit"
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default ProductForm;
