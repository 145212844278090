import {useEffect, useState} from 'react';
import {Configuration, SkusApi} from '@/api';
import Button from '@/components/Button';
import CustomToast from '@/components/CustomToast';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import NumberInput from '@/components/NumberInput';
import Pagination from '@/components/Pagination';
import {useSettings} from '@/contexts/SettingsContext';
import {SearchProps} from '@/hooks/useSearch';
import {EmptyResult} from '@/models/Pagination';
import {Sku, SkuAssociation, Skus} from '@/models/Sku';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import DiscountStar from '@/modules/retailer/components/DiscountStar';
import Price from '@/modules/retailer/components/Price';
import PriceServiceImpl from '@/modules/retailer/services/PriceService';
import {ShoppingCartIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import toast from 'react-hot-toast';

interface ProductCatalogTableProps extends SearchProps {
    supplierId?: string | null;
    productCategoryId?: string | null;
    promotions?: boolean;
    onAddToCart: (sku: Sku, quantity: number) => void;
}

interface SkuQuantities {
    [skuId: string]: number;
}

type State = Skus & {
    quantities: SkuQuantities;
};

const initialState = {
    ...EmptyResult<Sku>(),
    quantities: {},
};

const priceService = new PriceServiceImpl();

const ProductCatalogTable = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    supplierId,
    productCategoryId,
    promotions,
    onAddToCart,
}: ProductCatalogTableProps) => {
    const [state, setState] = useState<State>(initialState);

    const {currencyCode, languageCode} = useSettings();
    const skusApi = new SkusApi(new Configuration(useAuth), {currencyCode, languageCode});

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: [
            'products',
            page,
            searchQuery,
            supplierId,
            productCategoryId,
            promotions,
            currencyCode,
            languageCode,
        ],
        queryFn: async () => {
            const limit = 10;
            return skusApi.findSkus(
                searchQuery,
                {
                    supplierId: supplierId || undefined,
                    productCategoryId: productCategoryId || undefined,
                    promotions: promotions,
                },
                page * limit - limit,
                limit,
                [],
                [SkuAssociation.ALL],
            );
        },
    });

    useEffect(() => {
        setState((prevState): State => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount ||  0,
                estimatedTotalCount: data?.estimatedTotalCount ||  0,
                quantities: data?.items.reduce((acc: SkuQuantities, sku: Sku) => {
                    acc[sku.id] = 1;
                    return acc;
                }, {}) || {},
            };
        });
    }, [data, isLoading, isFetching, supplierId]);

    const handleQuantityChange = (skuId: string, newQuantity: number) => {
        setState((prevState) => ({
            ...prevState,
            quantities: {
                ...prevState.quantities,
                [skuId]: newQuantity,
            },
        }));
    };

    const handleAddToCartClick = (sku: Sku) => {
        const quantity = state.quantities[sku.id] || sku.moq || 1;
        onAddToCart(sku, quantity);
        toast.custom((t) => (
            <CustomToast
                id={t.id}
                message={`Successfully added ${sku.name} to the cart!`}
                visible={t.visible}
            />
        ));
    };

    const {
        items,
        quantities,
        totalCount,
        estimatedTotalCount,
    } = state;

    return (
        <div className="table-container table-custom">
            <div className="table-wrapper">
                <table className={`table ${isLoading ? 'table-loading' : ''}`}>
                    <thead className="table-header">
                    <tr className="table-header-row">
                        <th className="px-3 py-4 pl-6 text-left text-gray-600 font-semibold text-sm w-[40%]">Product Name</th>
                        <th className="table-cell">SKU</th>
                        <th className="table-cell">Price</th>
                        <th className="px-3 py-4 pl-12 text-left text-gray-600 font-semibold text-sm w-48">Order Quantity</th>
                    </tr>
                    </thead>
                    <tbody className="table-body">
                    {items.map((sku, index) => {
                        const unitPrice = priceService.calculateUnitPrice(sku);
                        return (
                            <tr key={sku.id + index} className="table-row">
                                <td className="px-3 py-1">
                                    <div className="flex gap-4 items-center">
                                        <div className="relative pr-4">
                                            {unitPrice && <DiscountStar price={unitPrice}/>}
                                            {sku.images && sku.images[0] &&
                                                <div className="min-h-24 w-24">
                                                    <img src={sku.images[0].url} alt="Product Image"/>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <div>{sku.name}</div>
                                            <div className="text-sm text-gray-600">Min. Order Quantity: <span>{sku.moq}</span></div>
                                            <div className="text-sm text-gray-600">Unit count: <span>{sku.quantity}</span></div>
                                        </div>
                                    </div>
                                </td>
                                <td className="table-cell">{sku.code}</td>
                                <td className="table-cell">
                                    <Price price={unitPrice}/>
                                </td>
                                <td className="pr-6 px-3 py-3">
                                    <div className="flex gap-4 lg:gap-6 flex-col lg:flex-row">
                                        <NumberInput
                                            className="w-full lg:w-16"
                                            value={quantities[sku.id]}
                                            onChange={(newQuantity) => {
                                                handleQuantityChange(
                                                    sku.id,
                                                    newQuantity,
                                                );
                                            }}
                                        />
                                        <Button
                                            onClick={() => handleAddToCartClick(sku)}
                                            icon={<ShoppingCartIcon className="w-5 h-5 mr-2"/>}
                                            variant="primary"
                                            size="sm"
                                            nowrap
                                        >
                                            Add to Cart
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="table-loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={totalCount || 0}
                    estimatedTotalCount={estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default ProductCatalogTable;
