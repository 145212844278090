import {FormEvent, useState} from 'react';
import Button from '@/components/Button';
import SearchForm from '@/components/SearchForm';
import SupplierSelectBox from '@/components/SupplierSelectBox';
import useSearch from '@/hooks/useSearch';
import {Supplier} from '@/models/Supplier';
import ProductTable from '@/modules/settings/components/ProductTable';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {SettingsProductAddPath, SettingsProductImportPath} from '@/modules/settings/paths';
import {CubeIcon, DocumentArrowDownIcon, PlusIcon} from '@heroicons/react/24/outline';
import {useSearchParams} from 'react-router-dom';

interface State {
    supplierId: string | null;
}

const ProductList = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        searchQuery,
        currentSearchQuery,
        page,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const supplierIdParam = searchParams.get('supplierId');

    const [state, setState] = useState<State>({
        supplierId: supplierIdParam,
    });

    const search = (searchQuery: string | null, supplierId: string | null) => {
        setSearchParams(new URLSearchParams({
            page: '1',
            query: searchQuery || '',
            supplierId: supplierId || '',
        }));
    };

    const {
        supplierId,
    } = state;

    const handleSupplierSelect = (supplier?: Supplier | null) => {
        setState(prevState => ({
            ...prevState,
            supplierId: supplier?.id || null,
        }));
        search(
            currentSearchQuery,
            supplier?.id || null,
        );
    };

    const handleSearchSubmit= (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        search(
            currentSearchQuery,
            supplierId,
        );
    };

    return (
        <>
            <SettingsHeader
                title="Products"
                icon={<CubeIcon className="w-6 h-6"/>}
            >
                <Button
                    to={SettingsProductImportPath}
                    icon={<DocumentArrowDownIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Import
                </Button>
                <Button
                    to={SettingsProductAddPath}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Product
                </Button>
            </SettingsHeader>

            <div className="flex flex-col gap-4">
                <div className="flex-1">
                    <SearchForm
                        as="Fragment"
                        value={searchQuery}
                        onSubmit={handleSearchSubmit}
                        onChange={setSearchQuery}
                        placeholder="Search by product code or name"
                    />
                </div>
                <div className="flex flex-col lg:flex-row gap-4 mb-8 lg:items-end">
                    <div className="sm:w-96 lg:w-80">
                        <SupplierSelectBox
                            value={supplierId}
                            onSelect={handleSupplierSelect}
                        />
                    </div>
                </div>
            </div>

            <ProductTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                supplierId={supplierIdParam}
            />
        </>
    );
};

export default ProductList;
