import {useState} from 'react';
import {Configuration, SkusApi} from '@/api';
import Button from '@/components/Button';
import CustomToast from '@/components/CustomToast';
import FileUpload from '@/components/FileUpload';
import SupplierSelectBox from '@/components/SupplierSelectBox';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import FieldDescriptions from '@/modules/settings/pages/ProductImport/FieldDescriptions';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {withMinimumDelay} from '@/utils/delay';
import {withLoading} from '@/utils/loading';
import {DocumentArrowDownIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

const ProductImport = () => {
    const {currentUser} = useAuth();

    const {
        storeId: userStoreId,
        supplierId: userSupplierId,
    } = currentUser() || {};

    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);

    const [
        supplierId,
        setSupplierId,
    ] = useState<string | null>(userSupplierId || null);

    const skusApi = new SkusApi(new Configuration(useAuth));

    const handleFileChange = (files: File[]) => {
        const selectedFile = files[0] || null;
        setFile(selectedFile);
    };

    const handleFileUpload = async () => {
        if (!file || !supplierId) {
            return;
        }

        try {
            await withLoading(withMinimumDelay(
                () => skusApi.bulkUpdateSkus(supplierId, file)
                , 1000), setLoading)();

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Products imported successfully!'}
                    visible={t.visible}
                />
            ));
        } catch (error) {
            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Failed to upload the file. Please try again.'}
                    visible={t.visible}
                    variant="error"
                />
            ));
            console.error('Error uploading file', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <SettingsHeader
                title="Import Products"
                icon={<DocumentArrowDownIcon className="w-6 h-6"/>}
            />

            <div className="mt-6">
                {!userSupplierId && (
                    <div className="mb-6">
                        <SupplierSelectBox
                            value={supplierId || null}
                            onSelect={(supplier) => setSupplierId(supplier?.id || null)}
                            createdByStoreId={userStoreId}
                        />
                    </div>
                )}

                <FileUpload
                    selectedFiles={file ? [file] : undefined}
                    onFileChange={handleFileChange}
                    accept={['csv', 'xlsx']}
                />

                {file && (
                    <div className="mt-4">
                        <Button
                            onClick={handleFileUpload}
                            variant="primary"
                            loading={loading}
                            disabled={!supplierId}
                            className="mt-4 mb-4"
                        >
                            {loading ? 'Uploading...' : 'Upload File'}
                        </Button>

                        {!supplierId &&
                            <p className="text-red-700 text-sm">Please select a supplier before uploading.</p>
                        }
                    </div>
                )}
            </div>

            <div className="mt-6">
                <h2 className="text-lg font-medium text-gray-900">Download Import Templates</h2>
                <p className="mt-2 text-sm text-gray-600">
                    Before importing, please download one of the following templates and fill it with your product
                    data.
                </p>

                <div className="mt-4 space-x-4">
                    <Button
                        to="/products-template.csv"
                        download
                        variant="secondary"
                        className="inline-flex"
                    >
                        Download CSV Template
                    </Button>
                    <Button
                        to="/products-template.xlsx"
                        download
                        variant="secondary"
                        className="inline-flex"
                    >
                        Download XLSX Template
                    </Button>
                </div>
            </div>

            <FieldDescriptions/>
        </>
    );
};

export default ProductImport;
