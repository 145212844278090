import {getCurrencyFormatter} from '@/utils/currency';

interface Price {
    currencyCode: string;
    amount: number;
}

const isPrice = (price: Price | number): price is Price => {
    return (price as Price).amount !== undefined &&
        (price as Price).currencyCode !== undefined;
};

const formatPrice = (price?: Price | number, currencyCode?: string): string | undefined => {
    if (price === undefined || (typeof price === 'object' && !isPrice(price))) {
        return;
    }

    const targetCurrencyCode = typeof price === 'object' ? price.currencyCode : currencyCode;
    if (!targetCurrencyCode) {
        return;
    }

    const formatter = getCurrencyFormatter(targetCurrencyCode);
    if (!formatter) {
        return;
    }

    const amount = typeof price === 'object' ? price.amount : price;
    if (isNaN(amount)) {
        return;
    }

    return formatter.format(amount);
};

export default formatPrice;
