import {Configuration, SuppliersApi} from '@/api';
import useGetSupplierQuery from '@/api/queries/useGetSupplierQuery';
import CustomToast from '@/components/CustomToast';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {SupplierCreate, SupplierUpdate} from '@/models/Supplier';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import SupplierForm from '@/modules/retailer/components/SupplierForm';
import {SettingsPath} from '@/modules/retailer/paths';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {TruckIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import {useNavigate, useParams} from 'react-router-dom';

const SupplierEdit = () => {
    const navigate = useNavigate();

    const {supplierId} = useParams();

    const {
        isLoading,
        supplier,
        invalidateQuery,
    } = useGetSupplierQuery(supplierId);

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const handleSubmit = async (supplierUpdate: SupplierCreate | SupplierUpdate) => {
        if (!supplier) {
            return;
        }

        const updatedSupplier: SupplierUpdate = {
            ...supplier,
            ...supplierUpdate,
        };

        try {
            await suppliersApi.updateSupplier(supplier.id, updatedSupplier);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Supplier updated successfully!'}
                    visible={t.visible}
                />
            ));

            await invalidateQuery();

            navigate(SettingsPath.supplier);
        } catch (error) {
            console.error('Error while updating supplier:', error);
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!supplier) {
        return;
    }

    return (
        <>
            <SettingsHeader
                title="Edit Supplier"
                icon={<TruckIcon className="w-6 h-6"/>}
            />

            <SupplierForm
                supplier={supplier}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default SupplierEdit;
